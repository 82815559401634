import { Index, Match, Show, Switch, VoidComponent } from "solid-js";
import { ContestsService } from "../../../gen/webapi/v1/contests_connect";
import { queryClient, useClient } from "../../api-client";
// import LiveContestActual from "../contest/LiveContestsSection";
import { SectionContainer } from "../contest/ContestSection";
import { QueryClientProvider, createQuery } from "@tanstack/solid-query";
import ErrorMessage from "../ui/Error";
import ContestCardLoadingTall from "../contest/ContestCardLoadingTall";
import { PastContestCard } from "../contest/ContestCard";
import { UserContestResult } from "../../../gen/webapi/v1/contests_pb";

const client = useClient(ContestsService);

const RecentContests: VoidComponent = () => {
    return <SectionContainer>
        <QueryClientProvider client={queryClient}>
            <RecentContestsActual />
        </QueryClientProvider>
    </SectionContainer>
}

const RecentContestsActual: VoidComponent = () => {
    const query = createQuery(() => ['recentContests'],
        () => client.listPastContests({}),
        {
            refetchInterval: 5_000
        })

    const showRefreshError = () => query.data && !!query.error && (query.isLoadingError || query.errorUpdateCount > 1)

    return <>
        <div class="grid grid-flow-row grid-cols-1 lg:grid-cols-2 justify-between align-baseline
        mt-2 mb-4 gap-2">

            <h2
                id="recent-contests"
                class="text-2xl px-2 w-fit font-bold tracking-tight leading-normal transition-opacity"
                classList={{
                    'opacity-60': showRefreshError()
                }}
            >
                Recent Contests
            </h2>

            <Show when={showRefreshError()}>
                <li class="px-2 text-sm flex gap-2 items-center justify-start lg:justify-end opacity-80 hover:opacity-100">
                    <div class="flex justify-center items-center h-2 w-2">
                        <span class="animate-ping absolute inline-flex h-2.5 w-2.5 rounded-full bg-red-400 opacity-50"></span>
                        <span class="relative inline-flex rounded-full h-full w-full bg-orange-500"></span>
                    </div>
                    <ErrorMessage noIcon={true} text="Could not refresh data. Check connectivity!" />
                </li>
            </Show>
        </div>

        <div class="grid -mx-2 -mb-2 sm:-mx-4 sm:-mb-4">
            <ul class="grid grid-flow-col items-center pb-4 gap-0.5 snap-x snap-mandatory overflow-x-auto">
                <Show when={query.data} fallback={
                    <Switch>
                        <Match when={query.isLoading && !query.isFetched}>
                            <div class="flex flex-row gap-4 overflow-hidden px-4">
                                <Loading />
                            </div>
                        </Match>

                        <Match when={query.isError || (query.isFetching && !query.data)}>
                            <>
                                {() => console.error({ err: query.error })}

                                <div class="relative overflow-hidden">
                                    <div class="flex flex-row gap-4 px-4 py-2">
                                        <Loading />
                                    </div>

                                    <div class="absolute -left-0.5 -right-0.5 -top-0.5 -bottom-0.5 
							flex flex-col gap-2 justify-center items-center 
							p-4 pt-5 rounded-md backdrop-blur-sm">
                                        <ErrorMessage text="Error loading contests" />
                                    </div>
                                </div>
                            </>
                        </Match>
                    </Switch>
                }>
                    <Index each={query.data?.contests}>
                        {(contest) =>
                            <a href={`/contest/?id=${contest().id}`} class="shrink-0 self-stretch snap-center ms-4">
                                <PastContestCard uc={new UserContestResult({ contest: contest() })} />
                            </a>
                        }
                    </Index>
                </Show>
            </ul >
        </div>
    </>
}


const Loading: VoidComponent = () => {
    return <>
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
        <ContestCardLoadingTall />
    </>
}

export default RecentContests;